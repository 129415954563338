ion-checkbox.checkbox-disabled {
    opacity : 1;
}

.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
    opacity : 1;
}

.button-disabled {
    opacity : 1;
}

#action-btn {
    height: 100%;
}